<template>
  <div class="add-view-edit mail-company-list">
    <div class="content" v-loading="loading">
      <el-form
        :model="form"
        :rules="rules"
        @submit.native.prevent
        class="label-top-form"
        label-position="top"
        ref="ruleForm"
        size="medium"
        v-show="(isUnit && !isNext) || !isUnit"
      >
        <!-- 基本信息 -->
        <div class="ave-card basic-info">
          <p class="title" style="padding-top: 7px">基本信息</p>
          <!-- 企业 logo -->
          <!--          <div v-if="isUnit">-->
          <!--            <div class="content-title">-->
          <!--              企业logo-->
          <!--              <p-->
          <!--                  class="is-required"-->
          <!--                  style="top: 8px"-->
          <!--              >-->
          <!--                *-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <SingleMediaWall-->
          <!--                :disabled="false"-->
          <!--                v-model="form.logo"-->
          <!--                :width="80"-->
          <!--                :height="80"-->
          <!--                borderRadius="50%"-->
          <!--                :useDefaultAvatar="!form.logo"-->
          <!--                :showMask="false"-->
          <!--            />-->
          <!--          </div>-->

          <!--          <el-table v-if="isUnit"-->
          <!--              border-->
          <!--                    :data="[{}]"-->
          <!--              style="width: 100%; margin-top: 30px"-->
          <!--              class="thead-light"-->
          <!--              :header-cell-style="{ padding: '10px 30px' }"-->
          <!--              :cell-style="{ padding: ' 0 30px 10px', background: '#ffffff' }"-->
          <!--          >-->
          <!--            <el-table-column label="信息列表一">-->
          <!--              <template>-->
          <!--                <div class="table-column">-->
          <!--                  <el-form inline :rules="rules" label-position="top">-->
          <!--                    <el-form-item                      v-for="(item, index) in unitHead"-->
          <!--                                                       :key="index" :label="item.label" :prop="item.field">-->
          <!--                      <date-picker v-if="item.field === 'start_date'" v-model="form.start_date" placeholder="请选择" />-->
          <!--                      <template v-else-if="item.field === 'address'">-->
          <!--                        <el-button-group style="width: 100%; display: flex;">-->
          <!--                          <el-button style="max-width: 380px; " class="company-address" @click="handleAddressSelect">-->
          <!--                            {{form.address || '请选择'}}</el-button>-->
          <!--                          <el-button v-if="form.address" icon="el-icon-delete" @click="form.address = ''" />-->
          <!--                        </el-button-group>-->
          <!--                        <el-input v-model="form.address" v-show="false" />-->
          <!--                      </template>-->
          <!--                      <el-select v-else-if="item.field === 'category_ids'" v-model="form.category_ids" placeholder="请选择" style="width: 100%; " collapse-tags multiple clearable>-->
          <!--                        <el-option v-for="cate in categoryData" :key="cate.id" :label="cate.name" :value="cate.id">-->
          <!--                        </el-option>-->
          <!--                      </el-select>-->
          <!--                      <el-select v-else-if="item.field === 'type'" v-model="form.type" placeholder="请选择" style="width: 100%;" clearable>-->
          <!--                        <el-option v-for="cate in typeData" :key="cate.id" :label="cate.name" :value="cate.id">-->
          <!--                        </el-option>-->
          <!--                      </el-select>-->
          <!--                      <el-select v-else-if="item.field === 'credit_code_type'" v-model="form.credit_code_type" placeholder="请选择" style="width: 100%;" clearable>-->
          <!--                        <el-option v-for="busin in businessTypeData" :key="busin.id" :label="busin.name"-->
          <!--                                   :value="busin.id" />-->
          <!--                      </el-select>-->
          <!--                      <el-cascader v-else-if="item.field === 'city_index'" v-model="form.city_index" :options="provinceAndCityData"-->
          <!--                                   :props="{ expandTrigger: 'hover' }" style="width: 100%;">-->
          <!--                      </el-cascader>-->
          <!--                      <el-radio-group v-else-if="item.field === 'is_on_stock'" v-model="form.is_on_stock">-->
          <!--                        <el-radio :label="0">未上市</el-radio>-->
          <!--                        <el-radio :label="1">已上市</el-radio>-->
          <!--                      </el-radio-group>-->
          <!--                      <el-radio-group v-else-if="item.field === 'is_high_new'" v-model="form.is_high_new">-->
          <!--                        <el-radio :label="0">否</el-radio>-->
          <!--                        <el-radio :label="1">是</el-radio>-->
          <!--                      </el-radio-group>-->
          <!--                      <el-radio-group v-else-if="item.field === 'is_credit_rating'" v-model="form.is_credit_rating">-->
          <!--                        <el-radio :label="0">否</el-radio>-->
          <!--                        <el-radio :label="1">是</el-radio>-->
          <!--                      </el-radio-group>-->
          <!--                      <el-input v-else-->
          <!--                                style="width: 100%"-->
          <!--                                v-model="form[item.field]"-->
          <!--                                placeholder="请输入" />-->
          <!--                    </el-form-item>-->
          <!--                  </el-form>-->
          <!--                </div>-->
          <!--              </template>-->
          <!--            </el-table-column>-->
          <!--          </el-table>-->
          <div class="itembody">
            <div class="grid">
              <el-form-item label="企业名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入企业名称" />
              </el-form-item>
              <el-form-item label="企业简称" prop="alias_name">
                <el-input
                  :maxlength="4"
                  placeholder="请输入企业简称"
                  show-word-limit
                  v-model="form.alias_name"
                  :disabled="isType === 'recycle'"
                >
                </el-input>
              </el-form-item>
              <el-form-item label="行业类型" prop="category_ids">
                <!--                <el-select v-model="form.category_ids" placeholder="请选择行业类型" style="width: 100%; "-->
                <!--                           collapse-tags multiple clearable>-->
                <!--                  <el-option v-for="cate in categoryData" :key="cate.id" :label="cate.name" :value="cate.id">-->
                <!--                  </el-option>-->
                <!--                </el-select>-->
                <el-cascader
                  :options="categoryData"
                  :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    label: 'name',
                    value: 'id',
                    children: '_children',
                    emitPath: false,
                    multiple: true,
                  }"
                  @change="handleExportCatalogChange"
                  collapse-tags
                  filterable
                  ref="CascaderRef"
                  style="width: 100%"
                  v-model="form.category_ids"
                  :disabled="isType === 'recycle'"
                >
                </el-cascader>
              </el-form-item>
              <el-form-item label="企业类型" prop="type">
                <el-select
                  clearable
                  placeholder="请选择企业类型"
                  style="width: 100%"
                  v-model="form.type"
                  :disabled="isType === 'recycle'"
                >
                  <el-option
                    :key="cate.id"
                    :label="cate.name"
                    :value="cate.id"
                    v-for="cate in typeData"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="企业法人" prop="oper_name">
                <el-input
                  placeholder="请输入企业法人姓名"
                  v-model="form.oper_name"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>
              <el-form-item label="成立时间" prop="start_date">
                <date-picker
                  placeholder="请选择时间"
                  v-model="form.start_date"
                  :disabled="isType === 'recycle'"
                />
              </el-form-item>
              <el-form-item label="工商注册类型" prop="business_type">
                <el-select
                  clearable
                  placeholder="请选择工商注册类型"
                  style="width: 100%"
                  v-model="form.business_type"
                  :disabled="isType === 'recycle'"
                >
                  <el-option
                    :key="busin"
                    :label="busin"
                    :value="busin"
                    v-for="busin in businessTypeData"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="员工总数" prop="worker_sum">
                <el-input
                  placeholder="请输入员工总数"
                  v-model.number="form.worker_sum"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>
              <el-form-item label="代码类型" prop="credit_code_type">
                <el-select
                  clearable
                  placeholder="请选择代码类型"
                  style="width: 100%"
                  v-model="form.credit_code_type"
                  :disabled="isType === 'recycle'"
                >
                  <el-option
                    :key="busin.id"
                    :label="busin.name"
                    :value="busin.id"
                    v-for="busin in codeTypes"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="代码号" prop="credit_code">
                <el-input
                  placeholder="请输入代码号"
                  v-model="form.credit_code"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>
              <el-form-item label="注册资金" prop="regist_capi">
                <el-input
                  placeholder="请输入注册资金"
                  v-model="form.regist_capi"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>

              <el-form-item label="资产总额" prop="ent_cap">
                <el-input
                  placeholder="请输入资产总额"
                  v-model="form.ent_cap"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>
              <el-form-item label="所属地区" prop="city_index">
                <el-cascader
                  :options="getCityIndexAreaData()"
                  :props="{
                    expandTrigger: 'hover',
                    checkStrictly: true,
                    value: 'label',
                  }"
                  filterable
                  placeholder="请选择，支持搜索"
                  style="width: 100%"
                  v-model="form.city_index"
                  :disabled="isType === 'recycle'"
                >
                </el-cascader>
              </el-form-item>
              <el-form-item label="企业地址" prop="address">
                <!--                <el-popover v-if="form.address" trigger="hover" placement="top" :width="300">-->
                <!--                  <template #reference>-->
                <el-button-group style="width: 100%; display: flex">
                  <el-button
                    @click="handleAddressSelect"
                    class="company-address"
                    style="max-width: 380px"
                    :disabled="isType === 'recycle'"
                  >
                    {{ form.address || '请选择企业地址' }}</el-button
                  >
                  <!--                  </template>-->
                  <!--                  {{form.address}}-->
                  <!--                </el-popover>-->
                  <!--                <el-button v-else style="max-width: 380px" class="company-address" @click="handleAddressSelect">-->
                  <!--                  {{form.address || '请选择企业地址'}}</el-button>-->
                  <el-button
                    @click="form.address = ''"
                    icon="el-icon-delete"
                    v-if="form.address"
                    :disabled="isType === 'recycle'"
                  />
                </el-button-group>
                <el-input v-model="form.address" v-show="false"></el-input>
              </el-form-item>

              <el-form-item label="事务联系人" prop="contact_name">
                <el-input
                  placeholder="请输入事务联系人姓名"
                  v-model="form.contact_name"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>
              <el-form-item label="事务联系号码" prop="contact_info">
                <el-input
                  placeholder="请输入联系号码"
                  v-model="form.contact_info"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>

              <el-form-item label="电子邮箱" prop="email">
                <el-input
                  placeholder="请输入电子邮箱"
                  v-model="form.email"
                  :disabled="isType === 'recycle'"
                ></el-input>
              </el-form-item>
              <template v-if="isUnit">
                <el-form-item label="授权人姓名" prop="unit_username">
                  <el-input
                    placeholder="请输入授权人姓名"
                    v-model="form.unit_username"
                    :disabled="isType === 'recycle'"
                  ></el-input>
                </el-form-item>
                <el-form-item label="授权人手机号" prop="phone">
                  <el-input
                    placeholder="请输入授权人手机号"
                    v-model="form.phone"
                    :disabled="isType === 'recycle'"
                  ></el-input>
                </el-form-item>
              </template>
            </div>
            <hr class="ave-hr" />
            <div class="grid">
              <el-form-item label="上市公司" prop="is_on_stock">
                <el-radio-group :disabled="isType === 'recycle'" v-model="form.is_on_stock">
                  <el-radio :label="0">未上市</el-radio>
                  <el-radio :label="1">已上市</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="高新技术企业" prop="is_high_new">
                <el-radio-group :disabled="isType === 'recycle'" v-model="form.is_high_new">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="有纳税信用级别" prop="is_credit_rating">
                <el-radio-group :disabled="isType === 'recycle'" v-model="form.is_credit_rating">
                  <el-radio :label="0">否</el-radio>
                  <el-radio :label="1">是</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- <el-form-item label="管理员" prop="manage_user_id" class="input-btn">
  <el-input v-show="false" v-model="form.manage_user_id"></el-input>
  <el-button type="primary" plain @click="selectManage">
    {{form.manage_nickname?form.manage_nickname:'选择会员'}}</el-button>
                </el-form-item> -->
            </div>
          </div>
        </div>
        <!-- 自定义信息 -->
        <div
          class="ave-card basic-info"
          v-if="form.configs && form.configs.length > 0"
        >
          <p class="title" style="padding-top: 7px">自定义信息</p>
          <div class="itembody">
            <div class="grid">
              <div :key="index" v-for="(config, index) in form.configs">
                <el-form-item
                  :label="config.props.normal.label || config.title"
                  prop="config"
                >
                  <el-input
                    :placeholder="config.props.normal.value"
                    v-if="config.field === 'base-input'"
                    v-model="config.user_data"
                    :disabled="isType === 'recycle'"
                  >
                  </el-input>
                  <el-select
                    :placeholder="config.props.normal.value"
                    clearable
                    style="width: 100%"
                    v-if="config.field === 'base-select'"
                    v-model="config.user_data"
                    :disabled="isType === 'recycle'"
                  >
                    <el-option
                      :key="optionIndex"
                      :label="option"
                      :value="option"
                      v-for="(option, optionIndex) in config.props.normal
                        .options"
                    ></el-option>
                  </el-select>
                  <el-date-picker
                    :format="formatType[config.props.normal.dateType]"
                    :placeholder="config.props.normal.value"
                    :type="getDateType(config)"
                    style="flex: 1; width: 100%"
                    v-if="config.field === 'base-time-picker'"
                    v-model="config.user_data"
                    value-format="timestamp"
                    :disabled="isType === 'recycle'"
                  >
                  </el-date-picker>
                  <el-cascader
                    :options="getAreaData(config)"
                    :props="{ expandTrigger: 'hover', value: 'label' }"
                    clearable
                    style="width: 100%"
                    v-if="
                      config.field === 'base-area-picker' &&
                      config.props.normal.showType >= 0
                    "
                    v-model="config.user_data"
                    :disabled="isType === 'recycle'"
                  >
                  </el-cascader>
                  <template v-if="config.field === 'base-address-picker'">
                    <el-button-group style="width: 100%; display: flex">
                      <el-button
                        @click="handleAddressSelect(index)"
                        class="company-address"
                        style="max-width: 380px"
                        :disabled="isType === 'recycle'"
                      >
                        {{
                          config.user_data.address || config.props.normal.value
                        }}</el-button
                      >
                      <el-button
                        @click="config.user_data.address = ''"
                        icon="el-icon-delete"
                        v-if="config.user_data.address"
                        :disabled="isType === 'recycle'"
                      />
                    </el-button-group>
                    <el-input
                      v-model="config.user_data.address"
                      v-show="false"
                    ></el-input>
                  </template>
                  <!--                  <el-cascader-->
                  <!--                          v-else-if="config.field==='base-area-picker'"-->
                  <!--                          v-model="config.user_data"-->
                  <!--                          :options="provinceAndCityData"-->
                  <!--                          :props="{ expandTrigger: 'hover', value: 'label' }"-->
                  <!--                          style="width: 100%;"-->
                  <!--                  >-->
                  <!--                  </el-cascader>-->
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="ave-card logo">
          <p class="title">党、团、工会组织</p>
        </div>
        <!-- 是否建组织 -->
        <div :key="index" class="ave-card" v-for="(data, index) in foundData">
          <div class="itembody">
            <el-form-item
              :label="data.label"
              :prop="data.prop"
              class="horizontal-form-item"
              style="margin-bottom: 10px"
            >
              <el-radio-group
                @change="StatusChange($event, data)"
                v-model="data.status"
                :disabled="isType === 'recycle'"
              >
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form
              :model="data.data"
              :ref="data.prop"
              :rules="data.rules"
              class="form-data"
              v-if="data.status"
            >
              <el-form-item
                :key="index"
                :label="config.label"
                :prop="config.prop"
                class="from-item"
                v-for="(config, index) in data.config"
              >
                <el-select
                  placeholder="请选择"
                  style="width: 100%"
                  v-if="config.type === 1"
                  v-model="data.data[config.prop]"
                >
                  <el-option
                    :key="item"
                    :label="item"
                    :value="item"
                    v-for="item in data.options"
                  ></el-option>
                </el-select>
                <el-date-picker
                  placeholder="请选择时间"
                  style="width: 100%"
                  type="date"
                  v-if="config.type === 2"
                  v-model="data.data[config.prop]"
                  value-format="timestamp"
                >
                </el-date-picker>
                <el-input
                  placeholder="请输入"
                  type="number"
                  v-if="config.type === 3"
                  v-model="data.data[config.prop]"
                ></el-input>
                <el-input
                  placeholder="请输入"
                  type="text"
                  v-if="config.type === 4"
                  v-model="data.data[config.prop]"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 企业信息 -->
        <div class="ave-card info">
          <p class="title" style="margin-bottom: 10px">企业简介</p>
          <div class="itembody">
            <!-- 企业logo -->
            <el-form-item label="企业logo" prop="logo">
              <!--              <DefaultImageSelector v-model="form.logo" :DefaultImage="DefaultLogo" :width="118"-->
              <!--                :height="118">-->
              <!--                <p slot="info" class="info-title">推荐尺寸 96*96</p>-->
              <!--              </DefaultImageSelector>-->
              <single-media-wall
                :border-radius="4"
                :height="118"
                :width="118"
                v-model="form.logo"
                :disabled="isType === 'recycle'"
              >
              </single-media-wall>
            </el-form-item>

            <el-form-item label="企业介绍" prop="description">
              <el-input
                :rows="5"
                placeholder="请输入企业介绍"
                type="textarea"
                v-model="form.description"
                :disabled="isType === 'recycle'"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="经营范围" prop="scope">
              <el-input
                :rows="5"
                placeholder="请输入经营范围"
                type="textarea"
                v-model="form.scope"
                :disabled="isType === 'recycle'"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="企业形象" prop="res">
              <media-wall
                :UploadType="['video', 'image']"
                :borderRadius="4"
                :height="96"
                :limit="9 - form.res.length"
                :width="150"
                addText="上传图片/视频"
                v-model="form.res"
                :disabled="isType === 'recycle'"
              ></media-wall>
            </el-form-item>
            <el-form-item
              :label="`企业认证（${
                form.check_image && form.ident_status ? '已认证' : '未认证'
              }）`"
              prop="check_image"
            >
              <div class="check-image">
                <image-wall
                  :height="118"
                  :width="118"
                  addText="上传营业执照"
                  v-model="form.check_image"
                  :disabled="isType === 'recycle'"
                >
                </image-wall>
                <div
                  :style="{ width: '118px', height: '118px' }"
                  class="delete-check-image"
                  v-if="form.check_image && form.ident_status"
                >
                  <span @click="cancelAuth">取消认证</span>
                </div>
              </div>
              <div class="form-tip">
                <span
                  class="el-icon-info"
                  style="font-size: 15px; color: #c0c4cc"
                ></span>
                <span
                  >企业认证需要上传营业执照，认证后需要关联至少一名会员，并设为管理员；取消认证后管理权限将全部收回</span
                >
              </div>
            </el-form-item>
          </div>
        </div>
        <!-- 在该企业工作的会员 -->
        <div class="ave-card info">
          <p class="title">
            在该企业工作的会员<span class="title-right">已关联的会员</span>
          </p>
          <div class="itembody">
            <CompanyMemberList
              :ManageUser="ManageUserData"
              :getMembers="getCompanyMembers"
              :id="form.id"
              :is-unit="true"
              :isCompanyAuth="form.check_image !== ''"
              ref="members"
              v-if="!loading && form.name"
              :disabled="isType === 'recycle'"
            />
          </div>
        </div>
        <!-- 主要产品 -->
        <div class="ave-card info">
          <p class="title">主要产品</p>
          <div class="itembody">
            <ProductionList :disabled="isType === 'recycle'" v-model="form.company_product" />
          </div>
        </div>
        <!-- 发展历程 -->
        <div class="ave-card info">
          <p class="title">发展历程</p>
          <div class="itembody">
            <HistoryList :disabled="isType === 'recycle'" v-model="form.company_history" />
          </div>
        </div>
      </el-form>

      <div v-if="isNext">
        <div class="position-list">
          <p class="title" style="margin-bottom: 0">职务信息</p>
          <PositionList
            :socialText="social_role"
            ref="PositionList"
            type="add"
          />
        </div>
      </div>

      <FixedActionBar v-if="!isUnit" :zIndex="10">
        <template v-if="$route.params.type !== 'view'">
          <el-button
            :loading="saveLoading"
            @click="save('ruleForm')"
            type="primary"
            >保存</el-button
          >
          <el-button @click="cancel">取消</el-button>
        </template>
        <el-button v-else @click="cancel">返回</el-button>
      </FixedActionBar>

      <FixedActionBar v-else>
        <el-button @click="goBack" v-if="$route.name === 'AddUnit' && isNext"
          >上一步</el-button
        >
        <el-button
          :loading="saveLoading"
          @click="save('ruleForm')"
          type="primary"
          v-if="$route.params.type !== 'view'"
        >
          {{
            $route.name !== 'AddUnit' || isNext ? '保存' : '下一步'
          }}</el-button
        >
        <el-button @click="goBack" v-if="!isNext">返回</el-button>
      </FixedActionBar>

      <SelectMember
        :Members="memberSelected"
        :selectType="selectType"
        @changeSelect="handleSelect"
        v-model="openSelectMember"
      />
      <AddressSelector
        :detailForm="addressForm"
        @change="handleChangeAddress"
        v-model="openAddressSelector"
      />
    </div>
  </div>
</template>
<script>
import {
  companyCategory,
  companyDetail,
  SaveCompany,
} from '../../../api/company/company-detail'
import { getRegionSetting } from '@/modules/region-setting/api/list.js'
// import areaData from "@/modules/common/json/address.json";
import DatePicker from '@/base/components/Form/DatePicker'
import SelectMember from '@/modules/common/components/MemberSelector'
import MediaWall from '@/modules/common/components/MediaWall.vue'
import ImageWall from '@/modules/common/components/SingleMediaWall.vue'
import DefaultImageSelector from '@/modules/common/components/DefaultImageSelector.vue'
import CompanyMemberList from '../../../components/Company/CompanyMemberList.vue'
import ProductionList from '../../../components/Company/ProductionList.vue'
import HistoryList from '../../../components/Company/HistoryList.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import AddressSelector from '../../../../common/components/AddressSelector.vue'
import SingleMediaWall from '@/modules/common/components/SingleMediaWall'
import { phone, emailChecker } from '@/base/utils/regs'
import { getUnitDetail } from '@/modules/organization-admin/api/member/unit-detail'
import PositionList from '@/modules/organization-admin/components/Member/PositionList'
import {
  saveUnitData,
  unitValidate,
} from '@/modules/organization-admin/api/unit/detail'
import axios from 'axios'
import { timestamp } from '../../../../../base/utils/tool'

export default {
  data() {
    return {
      isNext: false,
      social_role: '',

      loading: false,
      saveLoading: false,
      form: {
        id: '0',
        manage_nickname: '',
        name: '', //企业名称
        type: '', // 种类
        alias_name: '',
        oper_name: '', //企业法人
        worker_sum: '', //员工总数
        regist_capi: '', //企业注册资金
        city_index: '', //企业所在城市
        city: '', //企业所在城市
        province: '', //企业所在城市
        region: '', // 企业所在区县
        start_date: -1, //企业成立时间
        address: '', //企业地址
        lat: '',
        lng: '',
        email: '',
        ent_cap: '', //企业资产总额
        is_on_stock: 0, //上市公司 0：否，1是
        is_high_new: 0, //高新技术企业 0：否，1是
        is_credit_rating: 0, //有纳税信用级别 0：否，1是
        category_ids: '', //行业类型（多选）
        credit_code_type: '', //企业代码类型
        contact_name: '', //商务联系人
        credit_code: '', //企业代码号
        contact_info: '', //联系号码
        // manage_user_id: "", //管理员
        // logo: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/admin/company_logo.png", //企业logo
        logo: '',
        party_organization: 0, //是否建立党组织
        group_organization: 0, //是否建立团组织
        labor_union_organization: 0, //是否建立工会组织
        check_image: '', //营业执照（企业认证）
        reason: '', //取消认证原因
        description: '', //企业介绍
        scope: '', //企业经营范围
        res: [], // 企业形象
        members: [], //企业员工
        company_product: [], //主要产品
        company_history: [], //发展历程
        configs: [], // 自定义字段
        phone: '',
        unit_username: '',
        business_type: '其他企业',
        currentI: null,
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        alias_name: [
          { required: true, message: '请输入企业简称', trigger: 'blur' },
        ],
        // worker_sum: [
        //   { required: true, message: "请输入员工总数", trigger: "blur" },
        //   { type: "number", message: "员工总数必须为数字值", trigger: "blur" },
        // ],
        city_index: [
          { required: true, message: '请选择所属地区', trigger: 'change' },
        ],
        // address: [
        //   { required: true, message: "请选择企业所在地址", trigger: "change" },
        // ],
        // category_id: [
        //   { required: true, message: "请选择行业类型", trigger: "change" },
        // ],
        // credit_code_type: [
        //   { required: true, message: "请选择企业代码类型", trigger: "change" },
        // ],
        // credit_code: [
        //   { required: true, message: "请输入企业代码号", trigger: "blur" },
        // ],
        // manage_user_id: [
        //   { required: true, message: "请选择管理员", trigger: "change" },
        // ],
        // description: [
        //   { required: true, message: "请填写企业介绍", trigger: "blur" },
        // ],
        // scope: [
        //   { required: true, message: "请填写企业经营范围", trigger: "blur" },
        // ],
        party_organization: [
          {
            validator: this.PartyOrganizationPass,
            trigger: 'change',
          },
        ],
        group_organization: [
          {
            validator: this.GroupOrganizationPass,
            trigger: 'change',
          },
        ],
        labor_union_organization: [
          {
            validator: this.UnionOrganizationPass,
            trigger: 'change',
          },
        ],
        email: [
          {
            validator: emailChecker,
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入授权人手机号',
            trigger: 'blur',
          },
          {
            validator: phone,
            trigger: 'blur',
          },
        ],
        unit_username: [
          {
            required: true,
            message: '请输入授权人姓名',
            trigger: 'blur',
          },
        ],
      },
      categoryData: [], //行业分类选项数据
      // 企业代码类型选项数据
      codeTypes: [
        { id: 0, name: '工商营业执照注册号' },
        { id: 1, name: '社会统一信用代码' },
        { id: 2, name: '企业税务登记证号' },
        { id: 3, name: '组织机构代码' },
      ],
      businessTypeData: [
        '内资企业',
        '港，澳，台投资企业',
        '外商投资企业',
        '其他企业',
      ],
      typeData: [
        { id: 0, name: '其他' },
        { id: 1, name: '合资企业' },
        { id: 2, name: '独资企业' },
        { id: 3, name: '国有企业' },
        { id: 4, name: '私营企业' },
        { id: 5, name: '全民所有制' },
        { id: 6, name: '集体所有制' },
        { id: 7, name: '股份制' },
        { id: 8, name: '有限责任制' },
      ],
      // 建立组织相关
      foundData: [
        {
          prop: 'party_organization',
          label: '是否建立党组织',
          status: 0,
          data: { organization: '', time: '', num: '', secretary: '' },
          config: [
            {
              label: '党组织机构',
              type: 1,
              prop: 'organization',
            },
            {
              label: '成立时间',
              type: 2,
              prop: 'time',
            },
            {
              label: '党员人数',
              type: 3,
              prop: 'num',
            },
            {
              label: '党委(总支或支部)书记',
              type: 4,
              prop: 'secretary',
            },
          ],
          options: ['党委', '党总支', '党支部'],
          rules: {
            organization: [
              {
                required: true,
                message: '请选择党组织机构',
                trigger: 'change',
              },
            ],
            time: [
              { required: true, message: '请选择成立时间', trigger: 'change' },
            ],
            num: [
              { required: true, message: '请输入党员人数', trigger: 'blur' },
            ],
            secretary: [
              {
                required: true,
                message: '请输入党委(总支或支部)书记',
                trigger: 'blur',
              },
            ],
          },
        },
        {
          prop: 'group_organization',
          label: '是否建立团组织',
          status: 0,
          data: { organization: '', time: '', num: '', secretary: '' },
          config: [
            {
              label: '团组织机构',
              type: 1,
              prop: 'organization',
            },
            {
              label: '成立时间',
              type: 2,
              prop: 'time',
            },
            {
              label: '团员人数',
              type: 3,
              prop: 'num',
            },
            {
              label: '团委(总支或支部)书记',
              type: 4,
              prop: 'secretary',
            },
          ],
          options: ['团委', '团总支', '团支部'],
          rules: {
            organization: [
              {
                required: true,
                message: '请选择团组织机构',
                trigger: 'change',
              },
            ],
            time: [
              { required: true, message: '请选择成立时间', trigger: 'change' },
            ],
            num: [
              { required: true, message: '请输入团员人数', trigger: 'blur' },
            ],
            secretary: [
              {
                required: true,
                message: '请输入团委(总支或支部)书记',
                trigger: 'blur',
              },
            ],
          },
        },
        {
          prop: 'labor_union_organization',
          label: '是否建立工会组织',
          status: 0,
          data: { organization: '', time: '', num: '', prop: '' },
          config: [
            {
              label: '工会主席姓名',
              type: 4,
              prop: 'organization',
            },
            {
              label: '成立时间',
              type: 2,
              prop: 'time',
            },
            {
              label: '工会人数',
              type: 3,
              prop: 'num',
            },
            {
              label: '占职工数比例(%)',
              type: 4,
              key: 'prop',
              prop: 'prop',
            },
          ],
          rules: {
            organization: [
              {
                required: true,
                message: '请输入工会主席姓名',
                trigger: 'change',
              },
            ],
            time: [
              { required: true, message: '请选择成立时间', trigger: 'change' },
            ],
            num: [
              { required: true, message: '请输入工会人数', trigger: 'blur' },
            ],
            prop: [
              {
                required: true,
                message: '请输入占职工数比例(%)',
                trigger: 'blur',
              },
            ],
          },
        },
      ],
      DefaultLogo:
        window.serverConfig.VUE_APP_ADMINURL +
        '/cyc/images/admin/company_logo.png',
      ManageUserData: {},
      addressForm: {
        lat: '',
        lng: '',
        address: '',
      },
      areaData: [], // 省市区选项数据
      cityIndexAreaData: [], // 所属地区
      openSelectMember: false, // 选择会员弹窗
      openAddressSelector: false,
      memberSelected: [],
      selectType: 0,
      formatType: ['yyyy', 'yyyy-MM', 'yyyy-MM-dd', 'yyyy-MM-dd HH:mm'],
      unitHead: [
        { field: 'name', label: '单位名称', required: true },
        { field: 'alias_name', label: '单位简称', required: false },
        { field: 'category_ids', label: '行业类型', required: false },
        { field: 'type', label: '企业类型', required: false },
        { field: 'oper_name', label: '企业法人', required: false },
        { field: 'start_date', label: '成立时间', required: false },
        { field: 'worker_sum', label: '员工总数', required: false },
        { field: 'credit_code_type', label: '代码类型', required: false },
        { field: 'credit_code', label: '代码号', required: false },
        { field: 'regist_capi', label: '注册资金', required: false },
        { field: 'ent_cap', label: '资产总额', required: false },
        { field: 'city_index', label: '所属地区', required: true },
        { field: 'address', label: '单位地址', required: false },
        { field: 'contact_name', label: '事务联系人', required: false },
        { field: 'contact_info', label: '联系号码', required: false },
        { field: 'email', label: '电子邮箱', required: false },
        // {field: 'email', label: '企业微信', required: false},
        // {field: 'oper_name', label: '法人代表', required: false},
        // {field: 'oper_name', label: '法人联系电话', required: false},
      ],
    }
  },
  computed: {
    //当前页面模式
    isType() {
      return this.$route.params.type
    },
    isUnit() {
      return ['AddUnit', 'EditUnit'].includes(this.$route.name)
    },
    //是否禁用
    // isDisabled() {
    //   return this.isType === "view";
    // },
    /* 只需要省市两级 */
    // provinceAndCityData() {
    //   this.cityData.forEach((el) => {
    //     if (el.children) {
    //       el.children.forEach((item) => {
    //         if (item.children) {
    //           item.children = undefined;
    //         }
    //       });
    //     }
    //   });
    //   return this.cityData;
    // },
  },
  methods: {
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false
    },
    getDateType(obj) {
      if (obj.props.normal.dateType === 0) {
        return 'year'
      } else if (obj.props.normal.dateType === 1) {
        return 'month'
      } else if (obj.props.normal.dateType === 2) {
        return 'date'
      }
      return 'datetime'
    },
    getAreaData(obj) {
      if (obj.field === 'base-area-picker') {
        let copyAreaData = JSON.parse(JSON.stringify(this.areaData))
        if (obj.props.normal.showType < 1) {
          copyAreaData.forEach((c) => {
            c.children = null
          })
        } else if (obj.props.normal.showType < 2) {
          copyAreaData.forEach((c) => {
            if (c?.children)
              c.children.forEach((cc) => {
                cc.children = null
              })
          })
        }
        return copyAreaData
      }
      return this.areaData
    },
    // 获取企业自定义配置
    getCompanyCustomConfigs() {
      this.loading = true
      companyDetail({ id: 0 })
        .then((res) => {
          const { data } = res
          this.form.configs = data.configs || []
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 获取企业详情
    getCompanyDetail() {
      this.loading = true
      companyDetail({ id: this.$route.query.cid || this.form.id })
        .then((res) => {
          const { data } = res
          const {
            party_organization,
            group_organization,
            labor_union_organization,
          } = data
          this.foundData[0].status = party_organization ? 1 : 0
          this.foundData[1].status = group_organization ? 1 : 0
          this.foundData[2].status = labor_union_organization ? 1 : 0
          if (party_organization) {
            this.foundData[0].data = party_organization
            if (party_organization.time.toString().length === 10) {
              this.foundData[0].data.time *= 1000
            }
          }
          if (group_organization) {
            this.foundData[1].data = group_organization
            if (group_organization.time.toString().length === 10) {
              this.foundData[1].data.time *= 1000
            }
          }
          if (labor_union_organization) {
            this.foundData[2].data = labor_union_organization
            if (labor_union_organization.time.toString().length === 10) {
              this.foundData[2].data.time *= 1000
            }
          }

          data.company_product = data.company_product
            ? data.company_product
            : []
          data.company_history = data.company_history
            ? data.company_history
            : []

          data.configs.forEach((f) => {
            if (f.field === 'base-time-picker') {
              if (f.user_data) f.user_data *= 1000
              else f.user_data = ''
            }
          })

          data.email = data.email || ''
          data.phone = data.phone || ''

          // 后端数据没有这个过渡字段，默认 undefined，所以需要先赋个默认值，否则影响饿了么的校验
          data.city_index = []

          this.form = data
          this.exchangeCity(1)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取所属地区限制
    async getHouseholdRegistration() {
      const baseUrl = window.serverConfig.VUE_APP_ADMINURL

      await new Promise((resolve) => {
        const fetchUrl = baseUrl + '/common/data/address.json?key=' + this.$store.state.tenant.id
        // const fetchUrl = baseUrl + '/common/data/address.json'
        fetch(fetchUrl, {
          // mode: 'no-cors',
        })
          .then(async (res) => {
            const data = await res.json()
            this.areaData = data
            resolve(data)
          })
          .catch((err) => {
            resolve(err)
          })
      })

      getRegionSetting({ region_key: 'company_region_setting' }).then((res) => {
        if (res.data.is_custom) {
          this.cityIndexAreaData = res.data.data
        } else {
          this.cityIndexAreaData = this.areaData
        }
      })
    },
    // 所属地区
    getCityIndexAreaData() {
      return this.cityIndexAreaData
    },
    // 企业所在城市数据转换
    exchangeCity(status) {
      if (status) {
        this.form.city_index = this.form.region?.split(',') || []
      } else {
        this.form.region = this.form.city_index?.join(',') || []
      }
    },
    //获取行业分类
    getCompanyCategory() {
      companyCategory()
        .then((res) => {
          const { data } = res
          this.categoryData = data
        })
        .catch(() => {})
    },
    // 获取企业员工列表
    getCompanyMembers(list) {
      this.form.members = list
    },
    //选择企业地址 && 自定义地址
    handleAddressSelect(i) {
      if (typeof i == 'number' && this.form.configs.length > 0) {
        //自定义地址
        this.currentI = i
        console.log(this.form.configs[i].user_data)
        this.addressForm = {
          lat: this.form.configs[i].user_data.lat,
          lng: this.form.configs[i].user_data.lng,
          address: this.form.configs[i].user_data.address,
        }
      } else {
        this.currentI = null
        this.addressForm = {
          lat: this.form.lat,
          lng: this.form.lng,
          address: this.form.address,
        }
      }

      this.openAddressSelector = true
    },
    handleChangeAddress(value) {
      //自定义地址
      if (this.currentI !== null) {
        this.form.configs[this.currentI].user_data.lat = value.lat
        this.form.configs[this.currentI].user_data.lng = value.lng
        this.form.configs[this.currentI].user_data.address = value.address
        this.currentI == null
      } else {
        this.form.lat = value.lat
        this.form.lng = value.lng
        this.form.address = value.address
      }
    },

    // 选择管理员弹窗
    selectManage() {
      this.memberSelected = [{ user_id: this.form.manage_user_id }]
      this.openSelectMember = true
    },
    // 选择会员操作
    handleSelect(e) {
      this.form.manage_user_id = e[0].user_id
      this.form.manage_nickname = e[0].nickname
      this.ManageUserData = { ...e[0], position: '' }
    },
    // 点击建立组织
    StatusChange(value, data) {
      this.form[data.prop] = value ? data.data : 0
    },
    // 建立党组织校验
    PartyOrganizationPass(rule, value, callback) {
      if (value && this.$refs['party_organization'].length) {
        this.$refs['party_organization'][0].validate((valid) => {
          if (valid) {
            console.log('验证通过')
            callback()
          } else {
            this.$message.error('建立党组织为必填项')
            this.saveLoading = false
          }
        })
      } else {
        callback()
      }
    },
    // 建立团组织校验
    GroupOrganizationPass(rule, value, callback) {
      if (value && this.$refs['group_organization'].length) {
        this.$refs['group_organization'][0].validate((valid) => {
          if (valid) {
            console.log('验证通过')
            callback()
          } else {
            this.$message.error('建立团组织为必填项')
            this.saveLoading = false
          }
        })
      } else {
        callback()
      }
    },
    //建立工会组织校验
    UnionOrganizationPass(rule, value, callback) {
      if (value && this.$refs['labor_union_organization'].length) {
        this.$refs['labor_union_organization'][0].validate((valid) => {
          if (valid) {
            console.log('验证通过')
            callback()
          } else {
            this.$message.error('建立工会组织为必填项')
            this.saveLoading = false
          }
        })
      } else {
        callback()
      }
    },
    // 取消认证
    cancelAuth() {
      this.$msgbox
        .confirm('确定要取消认证吗？取消认证后管理权限将全部收回', '提示', {
          type: 'info',
        })
        .then((res) => {
          this.form.check_image = ''
        })
        .catch((err) => {})
    },
    // 保存
    save(formName) {
      this.saveLoading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // this.exchangeCity(0);
          let form = JSON.parse(JSON.stringify(this.form))
          form.region = form.city_index?.join(',') || ''
          this.foundData.forEach((element) => {
            let elData = JSON.parse(JSON.stringify(element.data))
            let timestampLen = elData.time.toString().length
            if (timestampLen === 13) elData.time /= 1000
            form[element.prop] = element.status ? elData : 0
          })
          form.configs.forEach((f) => {
            if (f.field === 'base-time-picker') {
              if (f.user_data) f.user_data /= 1000
              else f.user_data = null
            }
          })
          // console.log(form.configs.map(el => ({field: el.field, value: el.user_data})), 'OOOOOOOOOOOOOOO')

          const data = {
            ...form,
            members: this.$refs.members?.memberList || [],
          }
          for (let key in data) {
            if (typeof data[key] === 'object') {
              data[key] = JSON.stringify(data[key])
            }
          }
          if (this.isUnit && !this.isNext) {
            unitValidate({
              id: this.$route.query.cid || this.$route.params.user_id || '0',
              company: { ...data, id: this.$route.query.cid || '0' },
            })
              .then(() => {
                if (!this.$route.params.user_id) this.isNext = true
                this.saveLoading = false
              })
              .catch((err) => {
                console.log(err)
                this.saveLoading = false
              })

            /* 单位新增上一步 */
            if (!this.$route.params.user_id) return
          }

          /* 以下进行编辑保存/新增下一步保存 */
          if (this.isUnit) {
            if (this.$route.name === 'AddUnit')
              data.id = this.$route.query.cid || '0'
            const params = {
              id: this.$route.params.user_id || '0',
              company: data,
            }
            if (data.id === '0' || this.$route.query.cid) {
              params.positions = JSON.stringify(
                this.$store.getters.positionList
              )
            }
            saveUnitData(params)
              .then((res) => {
                this.$message.success(res.msg)
                this.saveLoading = false
                this.cancel()
              })
              .catch(() => {
                this.saveLoading = false
              })
          } else {
            SaveCompany(data)
              .then((res) => {
                this.$message.success(res.msg)
                this.saveLoading = false
                this.cancel()
              })
              .catch(() => {
                this.saveLoading = false
              })
          }
        } else {
          setTimeout(() => {
            var isError = document.getElementsByClassName('is-error')
            isError[0].scrollIntoView({ block: 'center', behavior: 'smooth' })
          }, 100)
          this.$message.error('请填写完整信息')
          this.saveLoading = false
        }
      })
    },
    // 点击返回
    goBack() {
      if (this.$route.name === 'AddUnit' && this.isNext) {
        this.isNext = false
        return
      }
      // this.$router.push({
      //   name: "CycUserList",
      //   params: { tab: this.$route.params.tab },
      //   query: {role: 'unit'}
      // });
      this.cancel()
    },
    cancel() {
      // if (this.$route.params.type === "view") {
      this.$router.go(-1)
      // } else if (this.isUnit) {
      //   this.$router.go(-1);
      // } else {
      //   this.$router.push({ name: "CycCompanyList" });
      // }
    },
  },
  created() {
    /** @type {string} */
    let id = this.$route.params.id
    let user_id = this.$route.params.user_id
    this.form.id = id

    if (this.isUnit) {
      if (!user_id) {
        this.$store.commit('setPositionList', [])
      }

      if (this.$route.query.cid) {
        this.getCompanyDetail()
      } else {
        this.loading = true
        getUnitDetail({
          id: user_id || 0,
        })
          .then((res) => {
            const { data } = res
            data.res = data.res || {}
            data.company_product = data.company_product
              ? data.company_product.filter((el) => !!el.product_name)
              : []
            data.company_history = data.company_history
              ? data.company_history.filter((el) => !!el.time)
              : []
            if (this.$route.name === 'AddUnit') this.form.configs = data.configs
            else {
              data.configs.forEach((f) => {
                if (f.field === 'base-time-picker') {
                  if (f.user_data) f.user_data *= 1000
                  else f.user_data = ''
                }
              })
              // 后端数据没有这个过渡字段，默认 undefined，所以需要先赋个默认值，否则影响饿了么的校验
              data.city_index = []
              this.form = data
            }
            this.exchangeCity(1)
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
          })
      }
    } else {
      // 获取企业详情
      if (id != 0) {
        this.getCompanyDetail()
      } else {
        this.getCompanyCustomConfigs()
      }
    }
    // 获取所属地区限制
    this.getHouseholdRegistration()
    //获取行业分类
    this.getCompanyCategory()
  },
  //调整title
  beforeRouteEnter(to, from, next) {
    const type = to.params.type
    switch (type) {
      case 'add':
        to.meta.title = '新增'
        break
      case 'view':
        to.meta.title = '查看'
        break
      case 'edit':
      case 'recycle':
        to.meta.title = '编辑'
        break
    }
    next()
  },
  components: {
    PositionList,
    SingleMediaWall,
    DatePicker,
    SelectMember,
    MediaWall,
    ImageWall,
    CompanyMemberList,
    ProductionList,
    HistoryList,
    FixedActionBar,
    AddressSelector,
    DefaultImageSelector,
  },
}
</script>

<style lang="scss" scoped>
.add-view-edit {
  .content {
    //卡片样式
    .ave-card {
      & + .ave-card {
        margin-top: 24px;
      }
      .title {
        padding: 19px 4px;
        margin-bottom: 24px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #e9e9e9;
      }
      .ave-hr {
        display: block;
        height: 2px;
        background-color: #e9e9e9;
        margin: 20px 0;
        border: none;
      }

      .form-data {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .from-item {
          width: 33%;
        }
      }
    }
    //表单样式
    .label-top-form {
      //单个横向
      ::v-deep.el-form-item {
        &.horizontal-form-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e9e9e9;
          .el-form-item__label {
            width: 200px;
            padding-bottom: 0;
          }
        }
      }
    }
    .form-data {
      .el-form-item {
        margin-bottom: 10px;
        ::v-deep.el-form-item__label {
          padding-bottom: 0;
        }
        ::v-deep.el-textarea {
          max-width: 700px;
        }
      }
    }
    .info {
      padding-top: 20px;
      ::v-deep.el-form-item__label {
        padding-bottom: 0;
      }
      ::v-deep.el-textarea {
        max-width: 700px;
      }
      //企业认证
      .check-image {
        > div {
          display: inline-block;
          vertical-align: middle;
        }
        .delete-check-image {
          margin-left: 10px;
          border: 1px dashed #dcdfe6;
          position: relative;
          span {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 76px;
            height: 36px;
            line-height: 36px;
            margin: auto;
            text-align: center;
            border: 1px solid rgb(255, 73, 73);
            color: rgb(255, 70, 70);
            border-radius: 4px;
            cursor: pointer;
            &:hover,
            &:active {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
.info-title {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 30px;
}

.title-right {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 10px;
}

.company-address {
  padding: 10px 15px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itembody {
  padding: 0 4px;
  max-width: 1024px;
  /* 以下设置会导致下拉框选项浮窗出现时滚动条闪烁问题 */
  /*overflow-x: auto;*/

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3%;

    ::v-deep.el-form-item {
      flex-grow: 0;
      min-width: 200px;

      .el-form-item__label {
        height: 30px;
        line-height: 30px;
        padding: 0;
      }
    }
  }
}

.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 15px;
  span + span {
    margin-left: 4px;
  }
}

.content-title {
  padding: 8px 0 10px;
  position: relative;

  .is-required {
    position: absolute;
    top: 0;
    left: -10px;
    color: #f56c6c;
  }
}

.table-column {
  display: flex;
  flex-wrap: wrap;
  /*padding-left: 30px;*/

  .el-select,
  .el-input,
  .el-cascader,
  .el-date-picker,
  .el-button-group {
    min-width: 220px;
    max-width: 220px;
  }

  .el-button-group {
    /*max-width: calc(220px * 2 + 10px);*/
  }
}

/*.input-box {*/
/*  width: 30%;*/
/*  padding-top: 12px;*/
/*  margin-right: 3%;*/
/*}*/

/*.thead-light {*/
/*  ::v-deep td {*/
/*    padding: 0 0 12px;*/
/*  }*/
/*}*/

.position-list {
  .title {
    height: 55px;
    line-height: 55px;
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    padding: 0 12px;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 20px;
  }
}
</style>
